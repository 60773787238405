export const aboutData = [
  { name: "14 anos na area de vendas" },
  { name: "Empresário e Empreendedor" },
  { name: "10 anos Gestor de alta performance" },
  { name: "6 anos Diretor Comercial Gerenciando" },
  { name: "gerenciei mais de 100 corretores Autônomos na mesma gestão" },
  { name: "3 Formações em Coaching" },
  { name: "Practioner em PNL" },
  { name: "Pós Graduado em Gestão de Pessoas, Liderança e Coaching-PUCRS" },
  {
    name: "Liderança,Inteligência Interpessoal e Comunicação Eficaz - Fundação Napoleon Hill.",
  },
];
